<template>
  <div :class="classNames" data-testid="announcementBoard">
    <div class="announcement-board__wrapper">
      <bg-list-item
        class="announcement-board__header"
        role="button"
        clickable
        @click="toggleOpen"
      >
        <div class="announcement-board__header-title">
          <bg-icon name="promote" class="bg-u-mr-xs" />
          <bg-text size="title-3">Billing Team Announcement</bg-text>
        </div>

        <template #right-content>
          <bg-icon
            class="announcement-board__header-toggler"
            name="chevron-down"
          />
        </template>
      </bg-list-item>

      <Transition name="announcement-board-content-transition">
        <bg-tabs v-if="isOpened" class="announcement-board__content">
          <bg-tab
            v-for="(announcement, i) in announcements"
            :key="i"
            :id="`announcement-${i}`"
            :title="capitalize(announcement.bse)"
          >
            <bg-list-item v-if="!loading">
              <div class="announcement-board__content-message">
                <bg-text v-if="announcement.content" size="body-2">
                  {{ announcement.content }}
                </bg-text>

                <bg-text
                  v-else
                  class="bg-u-text-neutral-600"
                  size="body-2"
                  italic
                >
                  Belum ada announcement untuk akun BSE ini
                </bg-text>
              </div>

              <template #right-content>
                <div class="announcement-board__content-action">
                  <bg-button
                    v-if="announcement.content"
                    variant="tertiary-naked"
                    @click="$emit('click-action', announcement)"
                  >
                    Ubah
                  </bg-button>

                  <bg-button-icon
                    v-else
                    variant="tertiary-naked"
                    icon="add-plus"
                    @click="$emit('click-action', announcement)"
                  >
                    Tambah
                  </bg-button-icon>
                </div>
              </template>
            </bg-list-item>
          </bg-tab>

          <div
            v-if="loading"
            data-testid="announcementBoardContentSkeleton"
            class="announcement-board__content-skeleton"
          >
            <bg-skeleton height="20px" width="242px" />
            <bg-skeleton class="bg-u-mt-sm" height="20px" width="188px" />
          </div>
        </bg-tabs>
      </Transition>
    </div>
  </div>
</template>

<script>
import {
  BgTabs,
  BgTab,
  BgText,
  BgIcon,
  BgListItem,
  BgButton,
  BgButtonIcon,
  BgSkeleton,
} from 'bangul-vue';

import { capitalize } from 'Utils/typography';

const DISTANCE_TO_NAVBAR = 28;

export default {
  name: 'BillingTrackerAnnouncementBoard',

  components: {
    BgTabs,
    BgTab,
    BgText,
    BgIcon,
    BgListItem,
    BgButton,
    BgButtonIcon,
    BgSkeleton,
  },

  props: {
    announcements: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpened: false,
      isSticked: false,
    };
  },

  computed: {
    classNames() {
      const base = 'announcement-board';

      return {
        [base]: true,
        [`${base}--open`]: this.isOpened,
        [`${base}--stick`]: this.isSticked,
      };
    },
  },

  mounted() {
    window.addEventListener('scroll', this.stickOnScroll);
    this.stickOnScroll();
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.stickOnScroll);
  },

  methods: {
    capitalize,
    toggleOpen() {
      this.isOpened = !this.isOpened;
    },
    stickOnScroll() {
      this.isSticked = window.scrollY > DISTANCE_TO_NAVBAR;
    },
  },
};
</script>

<style lang="scss" src="./BillingTrackerAnnouncementBoard.scss" scoped></style>
