<template>
  <div class="billing-tracker">
    <billing-tracker-announcement />

    <div class="bg-u-mx-xl bg-u-mt-xl">
      <bg-text class="bg-u-mb-xxl" size="heading-2" tag="h2">
        Billing Tracker
      </bg-text>

      <div class="billing-tracker__filter-container bg-u-mb-md">
        <bg-datepicker
          data-testid="billingTracker-monthPicker"
          class="billing-tracker__month-picker bg-u-mr-md"
          minimum-view="month"
          format="MMMM yyyy"
          size="md"
          v-model="selectedMonth"
          :disabled-dates="monthSelectorDisabledDate"
          :disabled="isLoading"
        />

        <validation-provider
          ref="searchValidationObserver"
          :name="searchBy"
          :rules="rulesSearchBy"
          v-slot="{ errors }"
        >
          <bg-field
            :error="!!errors.length"
            :message="errors[0]"
            class="billing-tracker__search-field"
          >
            <bg-search-bar
              size="md"
              class="billing-tracker__search bg-u-mr-md"
              placeholder="Cari"
              with-select-input
              v-model="searchValue"
              :select-options="searchOptions"
              :select-value="searchBy"
              @select-change="handleChangeSearchBy"
            />
          </bg-field>
        </validation-provider>

        <bg-button
          size="md"
          class="bg-u-mr-md"
          @click="isShowFilterModal = true"
        >
          <bg-badge-counter
            v-if="selectedFilterCount"
            :value="selectedFilterCount"
            variant="black"
          />
          <bg-icon
            v-else
            name="filter"
            size="sm"
            class="billing-tracker__filter-icon"
          />
          <span class="bg-u-ml-xs">Filter</span>
        </bg-button>

        <bg-button-icon
          icon="search"
          variant="primary"
          :disabled="isLoading"
          @click="searchButtonClicked"
        >
          Cari
        </bg-button-icon>

        <bg-button
          size="md"
          variant="tertiary-naked"
          @click="resetFilter"
          :disabled="isLoading"
        >
          Reset
        </bg-button>
      </div>

      <bg-text class="bg-u-text-neutral-600 bg-u-mb-md" size="body-2">
        <bg-skeleton v-if="isLoading" height="24px" width="256px" />
        <template v-else>{{ tableDataCounterText }}</template>
      </bg-text>

      <div class="billing-tracker__table-container">
        <billing-tracker-table
          :invoices="invoices"
          :is-loading="isLoading"
          :is-error="isError"
          @note-action="showNoteModal"
        />

        <billing-tracker-note-modal
          v-model="isShowNoteModal"
          v-bind="noteModalData"
          @successfully-saved="fetchInvoices"
        />

        <div
          v-if="isError || (!isLoading && !invoices.length)"
          class="billing-tracker__table-empty-state bg-u-pt-xxxl bg-u-pb-md bg-u-px-md"
        >
          <bg-text size="title-2">Data Tidak Ditemukan</bg-text>
          <bg-text size="body-2" class="bg-u-mt-sm">
            Data tidak ditemukan di filter atau kata kunci yang Anda gunakan.
          </bg-text>
        </div>
      </div>

      <bg-pagination
        v-if="!isError && !isLoading && totalPage > 1"
        class="billing-tracker__pagination"
        :value="currentPage"
        :page-total="totalPage"
        @input="fetchInvoices"
      />

      <billing-tracker-filter-modal
        data-testid="billingTrackerFilterModal"
        v-model="isShowFilterModal"
        :active-filters="activeFilters"
        :selected-month="selectedMonth"
        @on-apply-filter="applyFilter"
      />
    </div>
  </div>
</template>

<script>
import { dayjs, dateFormatterToSend } from 'Utils/formatter';
import { ValidationProvider } from 'vee-validate';
import '@admin/pages/BillingTracker/config/validationRules';

import {
  BgText,
  BgPagination,
  BgSkeleton,
  BgDatepicker,
  BgButton,
  BgField,
  BgSearchBar,
  BgBadgeCounter,
  BgIcon,
  BgButtonIcon,
} from 'bangul-vue';

import billingTrackerAPICall, {
  endpoint,
} from './mixins/billingTrackerAPICall';

import BillingTrackerTable from './components/BillingTrackerTable';
import BillingTrackerNoteModal from './components/BillingTrackerNoteModal';
import BillingTrackerFilterModal from './components/BillingTrackerFilterModal';
import BillingTrackerAnnouncement from './components/BillingTrackerAnnouncement';

const defaultSearchBy = 'tenant_name';
const defaultNoteModalData = {
  invoiceId: 0,
  id: 0,
  tag: '',
  description: '',
};
const allowedFilterKeys = [
  'start_date',
  'end_date',
  'payment_status',
  'contract_status',
  'bse_account',
  'fee_name',
  'follow_up_status',
  'tag',
];
const allowedSearchKeys = ['search_by', 'search_value'];

export default {
  name: 'BillingTracker',

  components: {
    BgText,
    BgPagination,
    BgSkeleton,
    BgDatepicker,
    BgButton,
    BgField,
    BgSearchBar,
    BgBadgeCounter,
    BgIcon,
    BgButtonIcon,
    ValidationProvider,
    BillingTrackerTable,
    BillingTrackerNoteModal,
    BillingTrackerFilterModal,
    BillingTrackerAnnouncement,
  },

  mixins: [billingTrackerAPICall],

  data() {
    return {
      isLoading: false,
      isError: false,
      invoices: [],
      fetchInvoicesOffset: 0,
      fetchInvoicesLimit: 10,
      totalInvoices: 0,
      selectedMonth: '',
      searchValue: '',
      searchBy: defaultSearchBy,
      searchOptions: [
        {
          val: 'tenant_name',
          label: 'Nama Penyewa',
          rules: 'tenant_name|min:3|max:50',
        },
        {
          val: 'phone_number',
          label: 'No. HP Penyewa',
          rules:
            'phone_format|phone_numeric|min_phone_number:8|max_phone_number:14',
        },
        {
          val: 'listing_name',
          label: 'Nama Properti',
          rules: 'min:3',
        },
        {
          val: 'notes',
          label: 'Catatan',
          rules: 'min:3',
        },
      ],
      isShowNoteModal: false,
      isShowFilterModal: false,
      noteModalData: { ...defaultNoteModalData },
      activeFilters: {},
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler({ query }) {
        const validQuery = this.filterValidQuery({ query });
        this.changeQuery(validQuery);

        this.selectedMonth = this.getDateStartOfMonth(
          validQuery.selected_month || validQuery.start_date
        ).toDate();
        this.searchBy = validQuery.search_by || defaultSearchBy;
        this.searchValue = validQuery.search_value || '';
        this.activeFilters = { ...validQuery };

        this.fetchInvoices();
      },
    },
    isShowNoteModal(isShow) {
      if (!isShow) {
        this.noteModalData = { ...defaultNoteModalData };
      }
    },
    selectedMonth(value) {
      this.activeFilters.start_date = '';
      this.activeFilters.end_date = '';

      const query = {
        ...this.activeFilters,
        selected_month: dateFormatterToSend(this.getDateStartOfMonth(value)),
      };

      this.changeQuery(this.filterValidQuery({ query }));
    },
  },

  computed: {
    rulesSearchBy() {
      const { rules } = this.searchOptions.find(
        option => option.val === this.searchBy
      );

      return rules;
    },
    currentPage() {
      return this.fetchInvoicesOffset / this.fetchInvoicesLimit + 1;
    },
    totalPage() {
      return Math.ceil(this.totalInvoices / this.fetchInvoicesLimit);
    },
    tableDataCounterText() {
      let currentTotalData = 0;
      const currentInvoicesCount = this.invoices.length;

      if (currentInvoicesCount) {
        currentTotalData =
          (this.currentPage - 1) * this.fetchInvoicesLimit +
          currentInvoicesCount;
      }

      return `${currentTotalData} dari ${this.totalInvoices} hasil pencarian`;
    },
    monthSelectorDisabledDate() {
      const startOfMonth = this.getDateStartOfMonth();

      return {
        to: startOfMonth.subtract(1, 'year').toDate(),
        from: startOfMonth.add(1, 'year').toDate(),
      };
    },
    selectedFilterCount() {
      const selectedFilter = this.filterValidQuery({
        query: this.activeFilters,
        filterKeys: allowedFilterKeys,
      });

      let selectedFilterCount = Object.entries(selectedFilter).length;

      if (this.activeFilters.start_date && this.activeFilters.end_date) {
        selectedFilterCount -= 1;
      }

      return selectedFilterCount;
    },
    activeSearchAndFilterPayload() {
      const defaultStartDate = dateFormatterToSend(
        this.getDateStartOfMonth(this.selectedMonth)
      );
      const defaultEndDate = dateFormatterToSend(
        this.getDateEndOfMonth(this.selectedMonth)
      );

      const dueDate = {
        start_date: this.activeFilters.start_date || defaultStartDate,
        end_date: this.activeFilters.end_date || defaultEndDate,
      };

      const search = {
        search_by: this.searchValue ? this.searchBy : '',
        search_value: this.searchValue || '',
      };

      const payload = this.filterValidQuery({
        query: {
          ...this.activeFilters,
          ...dueDate,
          ...search,
        },
        filterKeys: [...allowedFilterKeys, ...allowedSearchKeys],
      });

      return payload;
    },
  },

  methods: {
    async fetchInvoices(page = 1) {
      this.isLoading = true;
      this.isError = false;
      this.totalInvoices = 0;
      this.fetchInvoicesOffset = (page - 1) * this.fetchInvoicesLimit;

      const params = {
        ...this.activeSearchAndFilterPayload,
        offset: this.fetchInvoicesOffset,
        limit: this.fetchInvoicesLimit,
      };

      const { success, data } = await this.makeBillingTrackerAPICall(
        endpoint.getBillingTrackerInvoiceList,
        params
      );

      if (success) {
        this.invoices = data.invoices || [];
        this.totalInvoices = data.pagination?.total_invoices || 0;
      } else {
        this.isError = true;
      }

      this.isLoading = false;
    },
    isValidQueryParamValue(value) {
      const validCharactersRegex = /^[a-zA-Z0-9_/()\-@.* ]+$/;

      return validCharactersRegex.test(value);
    },
    getDateStartOfMonth(date) {
      return dayjs(date).startOf('month');
    },
    getDateEndOfMonth(date) {
      return dayjs(date).endOf('month');
    },
    showNoteModal(noteData = {}) {
      this.noteModalData = {
        ...defaultNoteModalData,
        ...noteData,
      };
      this.isShowNoteModal = true;
    },
    filterValidQuery({ query = {}, filterKeys = [] }) {
      const { start_date, end_date } = query;
      const validDateRangeValues = this.validateDateRange(start_date, end_date);

      const validQueryValues = this.validateQueryValues({
        ...query,
        ...validDateRangeValues,
      });

      const filterValidQuery = this.validateQueryKeys({
        query: { ...validQueryValues },
        filterKeys,
      });

      return filterValidQuery;
    },
    validateDateRange(startDate, endDate) {
      if (!startDate || !endDate) {
        return {};
      }

      const dayjsStartDate = dayjs(startDate);
      const dayjsEndDate = dayjs(endDate);

      const isSameMonth = dayjsStartDate.isSame(dayjsEndDate, 'month');
      const isGreaterThanStartDate = dayjsEndDate.isBefore(dayjsStartDate);
      const isNotValidDateRange = !isSameMonth || isGreaterThanStartDate;

      if (isNotValidDateRange) {
        return { start_date: '', end_date: '' };
      }

      return { start_date: startDate, end_date: endDate };
    },
    validateQueryValues(query) {
      const validQueryValues = {};

      Object.entries(query).forEach(([key, value]) => {
        if (key === 'payment_status' || key === 'contract_status') {
          let statusKeyValue = Array.isArray(value) ? value : [value];
          statusKeyValue = statusKeyValue.filter(this.isValidQueryParamValue);

          if (statusKeyValue.length) {
            validQueryValues[key] = statusKeyValue;
          }
        } else if (this.isValidQueryParamValue(value)) {
          validQueryValues[key] = value;
        }
      });

      return validQueryValues;
    },
    validateQueryKeys({ query, filterKeys = [] }) {
      if (!filterKeys.length) {
        return query;
      }

      const validQueryKeys = Object.entries(query).filter(([key]) =>
        filterKeys.includes(key)
      );

      return Object.fromEntries(validQueryKeys);
    },
    handleChangeSearchBy(val) {
      this.searchBy = val;
      this.searchValue = '';
    },
    validateSearch() {
      const validationObserver = this.$refs?.searchValidationObserver;

      if (!validationObserver) {
        return false;
      }

      const isValid = !validationObserver.errors.length;

      return !!isValid;
    },
    searchButtonClicked() {
      const isValidSearch = this.validateSearch();

      if (isValidSearch) {
        const validQuery = this.filterValidQuery({
          query: {
            ...this.activeSearchAndFilterPayload,
            selected_month: this.$route.query.selected_month || '',
            start_date: this.activeFilters.start_date || '',
            end_date: this.activeFilters.end_date || '',
          },
        });

        this.changeQuery(validQuery);
      }
    },
    resetFilter() {
      this.activeFilters = {};
      this.searchValue = '';
      this.searchBy = defaultSearchBy;

      this.$router.replace('/billing-tracker').catch(() => {});
    },
    applyFilter(payload) {
      this.activeFilters = payload;
    },
    changeQuery(query) {
      this.$router.replace({ query }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped src="./BillingTracker.scss"></style>
