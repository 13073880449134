<template>
  <div class="announcement">
    <billing-tracker-announcement-board
      :announcements="announcements"
      :loading="isLoading"
      @click-action="openFormModal"
    />

    <billing-tracker-announcement-form-modal
      v-model="isFormModalShown"
      :announcement="selectedAnnouncement"
      data-testid="billingTrackerAnnouncement-formModal"
      @close="closeFormModal"
      @finish-submit="onAnnouncementSubmissionFinish"
    />
  </div>
</template>

<script>
import BillingTrackerAnnouncementBoard from '../BillingTrackerAnnouncementBoard';
import BillingTrackerAnnouncementFormModal from '../BillingTrackerAnnouncementFormModal';

import billingTrackerAPICall, {
  endpoint,
} from '../../mixins/billingTrackerAPICall';

const BASE_ANNOUNCEMENT_ITEM = {
  id: 0,
  bse: '',
  content: '',
};

const BSE_NAMES = ['bella', 'okta', 'maya', 'dida', 'sintia'];

export default {
  name: 'BillingTrackerAnnouncement',

  mixins: [billingTrackerAPICall],

  components: {
    BillingTrackerAnnouncementBoard,
    BillingTrackerAnnouncementFormModal,
  },

  data() {
    return {
      isLoading: true,
      isFormModalShown: false,
      selectedAnnouncement: BASE_ANNOUNCEMENT_ITEM,
      announcementData: [],
    };
  },

  computed: {
    announcements() {
      return BSE_NAMES.map(bseName => {
        const announcementDetail =
          this.announcementData.find(data => data.bse === bseName) || {};

        return {
          bse: bseName,
          ...announcementDetail,
        };
      });
    },
  },

  created() {
    this.fetchAnnouncements();
  },

  methods: {
    openFormModal(announcement = {}) {
      this.selectedAnnouncement = {
        ...BASE_ANNOUNCEMENT_ITEM,
        ...announcement,
      };

      this.isFormModalShown = true;
    },

    closeFormModal() {
      this.selectedAnnouncement = BASE_ANNOUNCEMENT_ITEM;
      this.isFormModalShown = false;
    },

    async fetchAnnouncements() {
      this.isLoading = true;
      this.announcementData = [];

      const { success, data } = await this.makeBillingTrackerAPICall(
        endpoint.getAnnouncements
      );

      if (success && data?.billing_announcements) {
        this.announcementData = data.billing_announcements;
      }

      this.isLoading = false;
    },

    onAnnouncementSubmissionFinish() {
      this.$toast.show('Announcement berhasil tersimpan.');
      this.fetchAnnouncements();
    }
  },
};
</script>

<style lang="scss" src="./BillingTrackerAnnouncement.scss" scoped></style>
