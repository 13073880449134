<template>
  <bg-modal
    :value="isShowModal"
    title="Billing Team Announcement"
    button-main-text="Simpan"
    button-second-text="Kembali"
    class="billing-tracker-announcement-form-modal"
    :buttonMainProps="{ loading: isSubmitting }"
    @click-main-action="submit"
    @click-second-action="closeModal"
    @close="closeModal"
  >
    <div class="bg-u-pt-xs">
      <bg-field label="Akun BSE">
        <bg-input
          :value="bseNameDisplay"
          disabled
          class="billing-tracker-announcement-form-modal__bse-name"
          data-testid="billingAnnoucementForm-bseName"
        />
      </bg-field>

      <bg-field
        label="Isi announcement"
        for="content"
        message="Maksimal 1000 karakter"
      >
        <bg-textarea
          v-model.trim="content"
          name="content"
          :max-char="1000"
          rows="3"
          data-testid="billingAnnoucementForm-content"
        />
      </bg-field>
    </div>
  </bg-modal>
</template>

<script>
import { BgModal, BgField, BgInput, BgTextarea } from 'bangul-vue';

import { capitalize } from 'Utils/typography';

import { useModalVisibility } from '@/composables/useModalVisibility';

import billingTrackerAPICall, {
  endpoint,
} from '../../mixins/billingTrackerAPICall';

export default {
  name: 'BillingTrackerAnnouncementFormModal',

  components: {
    BgModal,
    BgField,
    BgInput,
    BgTextarea,
  },

  mixins: [billingTrackerAPICall],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    announcement: {
      type: Object,
      default: () => ({}),
      validator: data => ['bse', 'content'].every(key => key in data),
    },
  },

  setup(props, { emit }) {
    const { isShowModal } = useModalVisibility(props, emit);

    return {
      isShowModal,
    };
  },

  data() {
    return {
      content: '',
      isSubmitting: false,
    };
  },

  computed: {
    bseNameDisplay() {
      const { bse } = this.announcement;

      return bse ? capitalize(bse) : '';
    },
  },

  watch: {
    announcement: {
      immediate: true,
      handler({ content }) {
        this.content = content;
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    async submit() {
      this.isSubmitting = true;

      const isEdit = !!this.announcement.id;

      const payload = {
        ...this.announcement,
        content: this.content,
      };

      const { success, data } = isEdit
        ? await this.makeBillingTrackerAPICall(
            endpoint.updateAnnouncement,
            this.announcement.id,
            payload
          )
        : await this.makeBillingTrackerAPICall(
            endpoint.createAnnouncement,
            payload
          );

      this.isSubmitting = false;

      if (success && data?.id) {
        this.$emit('finish-submit');
        this.closeModal();
      }
    },
  },
};
</script>
