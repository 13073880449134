export { billingTrackerAPI as endpoint } from '@admin_endpoints/billing-tracker';

export default {
  methods: {
    async makeBillingTrackerAPICall(endpoint, ...args) {
      const response = {
        success: false,
        data: null,
        error: null,
      };

      try {
        const res = await endpoint(...args);
        response.data = res?.data;
        response.success = true;
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || 'Terjadi galat, silakan coba lagi.';
        this.$toast.show(errorMessage, { variant: 'error' });

        if (!error?.response) {
          this.$error.report(error);
        }

        response.error = error;
      }

      return response;
    },
  },
};
