<template>
  <table class="billing-tracker-table ss-table">
    <thead>
      <tr>
        <th
          v-for="(header, i) in headers"
          :key="i"
          :style="{ width: header.width }"
        >
          <bg-text size="title-5">{{ header.label }}</bg-text>
        </th>
      </tr>
    </thead>

    <tbody v-if="isLoading" data-testid="billingTrackerTableSkeleton">
      <tr v-for="i in 5" :key="i">
        <td v-for="j in headers.length" :key="j">
          <bg-skeleton height="48px" width="100%" />
        </td>
      </tr>
    </tbody>

    <tbody v-else-if="!isError && invoices.length">
      <billing-tracker-table-row
        v-for="invoice in invoices"
        :key="`${invoice.invoice_id}`"
        :invoice="invoice"
        v-on="$listeners"
        @follow-up-status-updated="invoice.follow_up_status = $event"
      />
    </tbody>
  </table>
</template>

<script>
import { BgText, BgSkeleton } from 'bangul-vue';

import BillingTrackerTableRow from '../BillingTrackerTableRow';

export default {
  name: 'BillingTrackerTable',

  components: {
    BgText,
    BgSkeleton,
    BillingTrackerTableRow,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    invoices: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        { label: 'Jatuh Tempo', width: '122px' },
        { label: 'BSE', width: '76px' },
        { label: 'Profil Penyewa', width: '176px' },
        { label: 'Nama Properti', width: '176px' },
        { label: 'Status Kontrak', width: '176px' },
        { label: 'Nama Biaya', width: '138px' },
        { label: 'Status Pembayaran', width: '172px' },
        { label: 'Catatan', width: '396px' },
        { label: '', width: '56px' },
      ],
    };
  },
};
</script>

<style lang="scss" scoped src="./BillingTrackerTable.scss"></style>
