/**
 * @typedef {Object} FlattenedCheckboxItem
 * @property {string} label
 * @property {string|number|boolean} value
 * @property {number|undefined} parentIndex - index of its parent
 * @property {boolean} checked - checked state
 * @property {Array<number>} childrenIndexes - indexes of its children
 * @property {number} col - grid column number
 * @property {number} indent - checkbox UI indentation
 */
/**
 * Compose and flatten checkbox items for NestedCheckbox component
 *
 * @param {Array<Object>} data - NestedCheckbox `data` prop
 * @returns {Array<FlattenedCheckboxItem>}
 */
export const composeCheckboxItems = data => {
  const checkboxItems = [];

  const compose = (items, parentIndex, parentCol = 1) => {
    items.forEach(({ label, value, col, children, noIndent }) => {
      const itemCol = col && col !== parentCol ? col : parentCol;

      const itemData = {
        label,
        value,
        parentIndex,
        indent: 0,
        col: itemCol,
        checked: false,
        childrenIndexes: [],
      };

      checkboxItems.push(itemData);

      if (parentIndex !== undefined) {
        if (!noIndent) {
          itemData.indent = checkboxItems[parentIndex].indent + 1;
        }

        checkboxItems[parentIndex].childrenIndexes.push(
          checkboxItems.length - 1
        );
      }

      if (children) {
        compose(children, checkboxItems.length - 1, itemData.col);
      }
    });
  };

  compose(data);

  return checkboxItems;
};
