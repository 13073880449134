<template>
  <bg-grid desktop-only>
    <bg-grid-item
      v-for="col in columns"
      :key="col"
      :col="12 / columns"
      :data-testid="`${checkboxId}-col-${col}`"
    >
      <bg-checkbox
        v-for="(item, i) in checkboxItems.filter(item => item.col === col)"
        :key="i"
        :label="item.label"
        :id="`${checkboxId}-${item.value}-chk`"
        :style="{ paddingLeft: `${24 * item.indent}px` }"
        class="bg-u-mb-md"
        v-model="item.checked"
        @input="handleCheckboxChanged($event, item)"
      />
    </bg-grid-item>
  </bg-grid>
</template>

<script>
import { BgCheckbox, BgGrid, BgGridItem } from 'bangul-vue';
import { composeCheckboxItems } from './utils/composeCheckboxItems';

export default {
  name: 'NestedCheckbox',
  props: {
    /**
     * Checkbox data type definition
     * @typedef {Object} CheckboxItem
     * @property {string} label
     * @property {string|number|boolean} value
     * @property {CheckboxItem[]} [children] - nested checkbox items
     * @property {boolean} [noIndent] - disable indentation
     * @property {number} [col] - grid column number
     */
    /**
     * An array of nested checkbox data.
     * @type {Array<CheckboxItem>} data
     */
    data: {
      type: Array,
      required: true,
    },
    checkboxId: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Number,
      default: 1,
    },
  },
  components: {
    BgCheckbox,
    BgGrid,
    BgGridItem,
  },
  data() {
    return {
      checkboxItems: composeCheckboxItems(this.data),
    };
  },
  computed: {
    checked() {
      return this.checkboxItems
        .filter(item => item.checked && !this.hasChildren(item))
        .map(item => item.value);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.checkboxItems.forEach(item => {
          const hasChildren = this.hasChildren(item);
          const isChecked = !hasChildren && value.includes(item.value);

          this.handleCheckboxChanged(isChecked, item);
        });
      },
    },
    checked: {
      handler(checked) {
        const isTheSameValue = this.value.join(',') === checked.join(',');

        if (!isTheSameValue) {
          this.$emit('input', checked);
        }
      },
    },
  },
  methods: {
    hasChildren(item) {
      return !!item.childrenIndexes.length;
    },
    handleCheckboxChanged(value, item) {
      item.checked = value;

      if (this.hasChildren(item)) {
        item.childrenIndexes.forEach(i => {
          this.handleCheckboxChanged(value, this.checkboxItems[i]);
        });
      }

      this.updateParentState(item.parentIndex);
    },
    updateParentState(parentIndex) {
      if (parentIndex !== undefined) {
        const parentItem = this.checkboxItems[parentIndex];

        const isAllChildSelected = parentItem.childrenIndexes.every(
          index => this.checkboxItems[index].checked
        );

        parentItem.checked = isAllChildSelected;

        this.updateParentState(parentItem.parentIndex);
      }
    },
  },
};
</script>
