/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import { min, max, alpha_spaces, numeric } from 'vee-validate/dist/rules';

extend('tenant_name', {
  ...alpha_spaces,
  message: 'Mohon gunakan nama penyewa yang sesuai',
});

extend('min', {
  ...min,
  params: ['length'],
  message: 'Minimal {length} karakter',
});

extend('max', {
  ...max,
  params: ['length'],
  message: 'Maksimal {length} karakter',
});

extend('phone_numeric', {
  ...numeric,
  message: 'Nomor handphone hanya dapat diisi dengan angka',
});

extend('phone_format', {
  validate(value = '') {
    return /^08.*/g.test(value);
  },
  message: 'Nomor handphone harus diawali dengan 08',
});

extend('min_phone_number', {
  ...min,
  params: ['length'],
  message: 'Nomor handphone kurang dari {length} karakter',
});

extend('max_phone_number', {
  ...max,
  params: ['length'],
  message: 'Nomor handphone lebih dari {length} karakter',
});
