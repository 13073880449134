<template>
  <tr
    class="table-row"
    :class="{ 'bg-u-bg-magenta-100': !invoice.follow_up_status }"
  >
    <td>{{ dueDateFormatted }}</td>
    <td>{{ invoice.bse_name }}</td>
    <td>
      <bg-text size="body-2" class="table-row__tenant-name">
        {{ invoice.tenant_name }}
      </bg-text>
      <bg-link
        v-if="invoice.tenant_phone_number"
        class="bg-u-mt-xxs"
        variant="medium"
        target="_blank"
        rel="noopener noreferrer"
        :href="whatsappLink"
      >
        {{ invoice.tenant_phone_number }}
      </bg-link>
    </td>
    <td>
      <bg-link
        v-if="invoice.property_id"
        :href="propertyDetailURL"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ invoice.property_name }}
      </bg-link>
    </td>
    <td>
      <bg-label-rainbow
        :color="contractStatusLabelColor"
        data-testid="contractStatusLabel"
      >
        {{ contractStatusLabel }}
      </bg-label-rainbow>
    </td>
    <td>{{ invoice.fee_name_label }}</td>
    <td class="table-row__payment-status-column">
      <bg-label-rainbow :color="paymentStatusLabelColor">
        {{ invoice.payment_status }}
      </bg-label-rainbow>
      <template v-if="isPaid">
        <br />
        <bg-label-rainbow class="bg-u-mt-xs">
          {{ paymentType }}
        </bg-label-rainbow>
      </template>
    </td>
    <td>
      <billing-tracker-note-list
        :notes="invoice.notes || []"
        @click-add-note="handleNoteAction"
        @click-note-item="handleNoteAction"
      />
    </td>
    <td class="table-row__action">
      <div v-if="isActionLoading" class="spinner bg-u-mt-xs" />

      <table-action-menu v-else>
        <bg-list-item
          v-for="item in actionItems"
          :key="item.icon"
          role="button"
          clickable
          @click="item.onClick"
        >
          <template #left-content><bg-icon :name="item.icon" /></template>
          <bg-text size="body-2">{{ item.label }}</bg-text>
        </bg-list-item>
      </table-action-menu>
    </td>
  </tr>
</template>

<script>
import { BgText, BgIcon, BgLabelRainbow, BgLink, BgListItem } from 'bangul-vue';

import TableActionMenu from '@admin_molecules/TableActionMenu';

import { dateFormatterToDisplay } from 'Utils/formatter';
import { getWhatsappLink } from 'Utils/whatsapp';

import BillingTrackerNoteList from '../BillingTrackerNoteList/BillingTrackerNoteList';

import billingTrackerAPICall, {
  endpoint,
} from '../../mixins/billingTrackerAPICall';

export default {
  name: 'BillingTrackerTableRow',
  components: {
    BgText,
    BgIcon,
    BgLabelRainbow,
    BgLink,
    BgListItem,
    BillingTrackerNoteList,
    TableActionMenu,
  },
  mixins: [billingTrackerAPICall],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActionLoading: false,
    };
  },
  computed: {
    invoiceId() {
      return this.invoice.invoice_id;
    },
    isPaid() {
      return this.invoice.payment_status === 'paid';
    },
    contractStatusLabel() {
      const { contract_status: status } = this.invoice;

      const labels = {
        active: 'Aktif',
        checkout_submitted: 'Ajukan Check-out',
        checkout_ready: 'Akan Check-out',
        checkout: 'Sudah Check-out'
      };

      return labels[status] || '-';
    },
    contractStatusLabelColor() {
      const { contract_status: status } = this.invoice;

      const colors = {
        active: 'green',
        checkout_submitted: 'red',
        checkout_ready: 'yellow',
        checkout: 'grey'
      };

      return colors[status] || 'grey';
    },
    paymentStatusLabelColor() {
      const { payment_status: paymentStatus } = this.invoice;

      const labelColors = {
        unpaid: 'red',
        paid: 'green',
        expired: 'grey',
      };

      return labelColors[paymentStatus] || 'red';
    },
    paymentType() {
      return this.invoice.is_mamipay ? 'Mamipay' : 'Not Mamipay';
    },
    dueDateFormatted() {
      return dateFormatterToDisplay(this.invoice.due_date, 'DD MMM');
    },
    whatsappLink() {
      return getWhatsappLink(this.invoice.tenant_phone_number);
    },
    propertyDetailURL() {
      return `/property-detail/${this.invoice.property_id}/overview`;
    },
    updateFollowUpActionText() {
      return this.invoice.follow_up_status
        ? 'Tandai Belum Follow-up'
        : 'Tandai Sudah Follow-up';
    },
    actionItems() {
      return [
        {
          icon: 'edit',
          label: 'Tambah Catatan',
          onClick: () => this.handleNoteAction(),
        },
        {
          icon: 'file-booking',
          label: this.updateFollowUpActionText,
          onClick: this.handleUpdateFollowUp,
        },
      ];
    },
  },
  methods: {
    handleNoteAction(noteData = {}) {
      this.$emit('note-action', {
        invoiceId: this.invoiceId,
        ...noteData,
      });
    },
    async handleUpdateFollowUp() {
      this.isActionLoading = true;

      const params = [
        endpoint.updateFollowUpStatus,
        this.invoiceId,
        {
          follow_up_status: !this.invoice.follow_up_status,
        },
      ];

      const { data } = await this.makeBillingTrackerAPICall(...params);

      if (data) {
        this.$emit('follow-up-status-updated', data.follow_up_status);
      }

      this.isActionLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./BillingTrackerTableRow.scss"></style>
