<template>
  <bg-modal
    v-model="isShowModal"
    data-testid="billingTrackerNoteModal"
    title="Catatan"
    description="Tambahkan catatan untuk data ini"
    button-main-text="Simpan"
    button-second-text="Kembali"
    @click-main-action="saveNote"
    @click-second-action="isShowModal = false"
    :close-on-click-backdrop="false"
    :button-main-props="{
      disabled: isDisableSaveButton,
      loading: isSaving,
    }"
    :button-second-props="{ disabled: isSaving }"
  >
    <bg-field label="Tag" class="bg-u-mt-md">
      <bg-select
        v-model="selectedTag"
        :options="TAG_OPTIONS"
        placeholder="Pilih tag"
      />
    </bg-field>

    <bg-field
      label="Isi Catatan"
      label-for="billingTrackerNoteDescription_txt"
      :error="noteDescriptionError.isExceedingMaxChar"
    >
      <bg-textarea
        id="billingTrackerNoteDescription_txt"
        v-model.trim="noteDescription"
        placeholder="Tulis catatan di sini"
        rows="4"
        :max-char="descriptionMaxChar"
      />

      <bg-text size="label-2" class="bg-u-text-neutral-500 bg-u-mt-xxs">
        Maksimal {{ descriptionMaxChar }} karakter
      </bg-text>
    </bg-field>
  </bg-modal>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api';
import { BgModal, BgSelect, BgTextarea, BgField, BgText } from 'bangul-vue';

import { useModalVisibility } from '@/composables/useModalVisibility';

import billingTrackerAPICall, {
  endpoint,
} from '../../mixins/billingTrackerAPICall';

import { TAG_OPTIONS, TAG_OPTIONS_MAP } from '../../constants/FilterData';

export default {
  name: 'BillingTrackerNoteModal',
  mixins: [billingTrackerAPICall],
  components: {
    BgModal,
    BgSelect,
    BgTextarea,
    BgField,
    BgText,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    invoiceId: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
    tag: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const descriptionMaxChar = 300;

    const isSaving = ref(false);

    const selectedTag = ref('');
    const noteDescription = ref('');

    const noteDescriptionError = computed(() => {
      const descriptionLength = noteDescription.value.trim().length;
      const isEmpty = !descriptionLength;
      const isExceedingMaxChar = descriptionLength > descriptionMaxChar;

      return {
        isEmpty,
        isExceedingMaxChar,
      };
    });

    const isDisableSaveButton = computed(() => {
      if (noteDescriptionError.value.isExceedingMaxChar) {
        return true;
      }

      return !selectedTag.value && noteDescriptionError.value.isEmpty;
    });

    const { isShowModal } = useModalVisibility(props, emit);

    watch(
      isShowModal,
      value => {
        if (value) {
          noteDescription.value = props.description;
          selectedTag.value = TAG_OPTIONS_MAP[props.tag] ? props.tag : '';
        } else {
          noteDescription.value = '';
          selectedTag.value = '';
        }
      },
      { immediate: true }
    );

    return {
      TAG_OPTIONS,
      isShowModal,
      descriptionMaxChar,
      isSaving,
      noteDescription,
      selectedTag,
      isDisableSaveButton,
      noteDescriptionError,
    };
  },
  methods: {
    sendSaveRequest() {
      const params = {
        tag: this.selectedTag,
        description: this.noteDescription,
      };

      if (this.id) {
        return this.makeBillingTrackerAPICall(
          endpoint.updateNote,
          this.id,
          params
        );
      }

      return this.makeBillingTrackerAPICall(endpoint.createNote, {
        ...params,
        invoice_id: this.invoiceId,
      });
    },
    async saveNote() {
      this.isSaving = true;

      const { success } = await this.sendSaveRequest();

      if (success) {
        this.$toast.show('Berhasil menyimpan catatan');
        this.$emit('successfully-saved');
        this.isShowModal = false;
      }

      this.isSaving = false;
    },
  },
};
</script>
