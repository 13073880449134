export const FOLLOW_UP_STATUS = [
  {
    val: 'true',
    label: 'Sudah follow-up',
  },
  {
    val: 'false',
    label: 'Belum follow-up',
  },
];

export const FEE_NAME = [
  {
    val: undefined,
    label: 'Biaya sewa',
    disabled: true,
  },
  {
    val: 'recurring',
    label: 'Recurring',
  },
  {
    val: 'Perpanjang sewa harian',
    label: 'Perpanjang sewa harian',
  },
  {
    val: 'Kekurangan biaya sewa kamar',
    label: 'Kekurangan biaya sewa kamar',
  },
  {
    val: 'Pindah tipe kamar/kos (relokasi)',
    label: 'Pindah tipe kamar/kos (relokasi)',
  },
  {
    val: 'Lainnya_sewa',
    label: 'Lainnya',
  },
  {
    val: undefined,
    label: 'Biaya tambahan',
    disabled: true,
  },
  {
    val: 'Parkir mobil',
    label: 'Parkir mobil',
  },
  {
    val: 'Parkir motor',
    label: 'Parkir motor',
  },
  {
    val: 'Sekamar berdua',
    label: 'Sekamar berdua',
  },
  {
    val: 'Tamu menginap',
    label: 'Tamu menginap',
  },
  {
    val: 'Listrik',
    label: 'Listrik',
  },
  {
    val: 'Air',
    label: 'Air',
  },
  {
    val: 'Wifi',
    label: 'Wifi',
  },
  {
    val: 'Laundry',
    label: 'Laundry',
  },
  {
    val: 'Deposit',
    label: 'Deposit',
  },
  {
    val: 'Penggantian kerusakan/kehilangan fasilitas',
    label: 'Penggantian kerusakan/kehilangan fasilitas',
  },
  {
    val: 'Lainnya_tambahan',
    label: 'Lainnya',
  },
];

export const PAYMENT_STATUS = [
  {
    label: 'Semua',
    value: 'paymentStatusAll',
    col: 1,
    children: [
      {
        label: 'Expired',
        value: 'expired',
        noIndent: true,
      },
      {
        label: 'Unpaid',
        value: 'unpaid',
        noIndent: true,
      },
      {
        label: 'Paid (All)',
        value: 'paymentPaidAll',
        noIndent: true,
        col: 2,
        children: [
          {
            label: 'Mamipay',
            value: 'paid_mamipay',
          },
          {
            label: 'Not in Mamipay',
            value: 'paid_not_in_mamipay',
          },
        ],
      },
    ],
  },
];

export const CONTRACT_STATUS = [
  {
    label: 'Aktif',
    value: 'active',
  },
  {
    label: 'Ajukan Check-out',
    value: 'checkout_submitted',
  },
  {
    label: 'Akan Check-out',
    value: 'checkout_ready',
  },
  {
    label: 'Sudah Check-out',
    value: 'checkout',
  },
];

export const TAG_OPTIONS = [
  { val: 'change_room_type', label: 'Pindah tipe kamar' },
  { val: 'change_due_date', label: 'Pindah jatuh tempo' },
  { val: 'blast', label: 'Blast' },
  { val: 'input_cash_out', label: 'Input cash out' },
  { val: 'refund', label: 'Refund (garansi)' },
  { val: 'take_out', label: 'Take out' },
  {
    val: 'terminate_contract',
    label: 'Putus kontrak (dari kosan)',
  },
  { val: 'will_check_out', label: 'Akan check-out' },
  { val: 'check_out', label: 'Check-out' },
];

export const TAG_OPTIONS_MAP = Object.fromEntries(
  TAG_OPTIONS.map(tag => [tag.val, tag.label])
);
