<template>
  <bg-modal
    v-model="isShowModal"
    :body-scroll="false"
    :close-on-click-backdrop="false"
    class="billing-tracker-filter-modal"
    title="Filter"
    desktop-size="lg"
    button-main-text="Terapkan"
    button-second-text="Hapus filter"
    @click-main-action="applyFilter"
    @click-second-action="removeFilter"
    :button-main-props="{
      disabled: isDisabledApplyButton,
    }"
  >
    <bg-text size="title-3" class="bg-u-mt-xxl bg-u-mb-md">
      Tanggal Jatuh Tempo
    </bg-text>

    <date-range-picker
      v-model="dueDates"
      id="filterDueDate"
      start-date-label="Mulai dari"
      end-date-label="Sampai dengan"
      :fixed-month="selectedMonth"
      :required="false"
    />

    <bg-grid class="bg-u-mt-sm">
      <bg-grid-item :col="6">
        <bg-text size="title-3" class="bg-u-mb-xs">Akun BSE</bg-text>

        <bg-select
          data-testid="bseAccount-dropdown"
          placeholder="Pilih akun BSE"
          v-model="bseAccount"
          :options="nameSortedBSEOptions"
          :options-value-key="bseOptionsValueKey"
          options-label-key="name"
        />
      </bg-grid-item>

      <bg-grid-item :col="6">
        <bg-text size="title-3" class="bg-u-mb-xs">Nama Biaya</bg-text>

        <bg-select
          data-testid="feeName-dropdown"
          placeholder="Pilih nama biaya"
          searchable
          v-model="feeName"
          :options="feeNameOptions"
        >
          <template #no-options>Nama biaya tidak tersedia</template>
        </bg-select>
      </bg-grid-item>
    </bg-grid>

    <bg-text size="title-3" class="bg-u-mb-lg bg-u-mt-sm">
      Status Pembayaran
    </bg-text>

    <nested-checkbox
      v-model="paymentStatus"
      :data="PAYMENT_STATUS"
      :columns="2"
      checkbox-id="billingTrackerFilterPaymentStatus"
    />

    <bg-text size="title-3" class="bg-u-mb-lg bg-u-mt-sm">
      Status Kontrak
    </bg-text>

    <nested-checkbox
      v-model="contractStatus"
      :data="CONTRACT_STATUS"
      checkbox-id="billingTrackerFilterContractStatus"
    />

    <bg-grid>
      <bg-grid-item :col="6">
        <bg-text size="title-3" class="bg-u-mb-xs">Status follow up</bg-text>

        <bg-select
          data-testid="followUpStatus-dropdown"
          placeholder="Pilih status follow up"
          v-model="followUpStatus"
          :options="followUpStatusOptions"
        />
      </bg-grid-item>

      <bg-grid-item :col="6">
        <bg-text size="title-3" class="bg-u-mb-xs">Tag</bg-text>

        <bg-select
          data-testid="tag-dropdown"
          placeholder="Pilih tag"
          v-model="tag"
          :options="tagOptions"
        />
      </bg-grid-item>
    </bg-grid>
  </bg-modal>
</template>

<script>
import { BgModal, BgText, BgGrid, BgGridItem, BgSelect } from 'bangul-vue';

import DateRangePicker from '@admin_molecules/DateRangePicker';
import NestedCheckbox from '@admin_organisms/NestedCheckbox';

import bseOptions from 'Utils/data/bseOptions.json';
import { dayjs, dateFormatterToSend } from 'Utils/formatter';

import { useModalVisibility } from '@/composables/useModalVisibility';

import {
  FOLLOW_UP_STATUS,
  PAYMENT_STATUS,
  FEE_NAME,
  TAG_OPTIONS,
  CONTRACT_STATUS,
} from '../../constants/FilterData';

export default {
  name: 'BillingTrackerFilterModal',

  components: {
    BgModal,
    BgText,
    BgGrid,
    BgGridItem,
    BgSelect,
    DateRangePicker,
    NestedCheckbox,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
    selectedMonth: {
      type: Date,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { isShowModal } = useModalVisibility(props, emit);

    return {
      isShowModal,
    };
  },

  data() {
    return {
      PAYMENT_STATUS,
      CONTRACT_STATUS,
      bseOptions,
      paymentStatus: [],
      contractStatus: [],
      dueDates: {
        startDate: null,
        endDate: null,
      },
      bseAccount: '',
      feeName: '',
      followUpStatus: '',
      tag: '',
      feeNameOptions: FEE_NAME,
      followUpStatusOptions: FOLLOW_UP_STATUS,
      tagOptions: TAG_OPTIONS,
    };
  },

  watch: {
    isShowModal(value) {
      if (value) {
        this.setFilterActive(this.activeFilters);
      }
    },
  },

  computed: {
    filterPayload() {
      const payload = {
        start_date: dateFormatterToSend(this.dueDates.startDate),
        end_date: dateFormatterToSend(this.dueDates.endDate),
        payment_status: this.paymentStatus,
        contract_status: this.contractStatus,
        bse_account: this.bseAccount,
        fee_name: this.feeName,
        follow_up_status: this.followUpStatus,
        tag: this.tag,
      };

      return Object.fromEntries(
        Object.entries(payload).filter(
          ([_, value]) => value !== '' && value.length
        )
      );
    },

    isDisabledApplyButton() {
      const { startDate, endDate } = this.dueDates;
      const isValidDate = (startDate && endDate) || (!startDate && !endDate);

      if (!isValidDate) {
        return true;
      }

      return !Object.entries(this.filterPayload).length;
    },

    nameSortedBSEOptions() {
      return bseOptions
        .filter(option => !!option[this.bseOptionsValueKey])
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    },

    bseOptionsValueKey() {
      const isDevEnv = process.env.VUE_APP_ENV === 'dev';
      return isDevEnv ? 'ownerEmailTest' : 'ownerEmail';
    },
  },

  methods: {
    setFilterActive(activeFilter) {
      this.bseAccount = activeFilter?.bse_account || '';
      this.feeName = activeFilter?.fee_name || '';
      this.followUpStatus = activeFilter.follow_up_status || '';
      this.tag = activeFilter.tag || '';
      this.paymentStatus = activeFilter.payment_status || [];
      this.contractStatus = activeFilter.contract_status || [];

      const startDateActive = activeFilter?.start_date;
      const endDateActive = activeFilter?.end_date;

      this.dueDates = {
        startDate: startDateActive ? dayjs(startDateActive).toDate() : null,
        endDate: endDateActive ? dayjs(endDateActive).toDate() : null,
      };
    },

    removeFilter() {
      this.$emit('on-apply-filter', {});
      this.isShowModal = false;
    },

    applyFilter() {
      this.$emit('on-apply-filter', this.filterPayload);
      this.isShowModal = false;
    },
  },
};
</script>

<style lang="scss" src="./BillingTrackerFilterModal.scss"></style>
