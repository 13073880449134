import api from '@admin/api/service';

const ANNOUNCEMENT_BASE_URL = '/singgahsini/api/billing-tracker/announcement';

const billingTrackerAPI = {
  getBillingTrackerInvoiceList(params) {
    return api.get('/singgahsini/api/billing-tracker', { params });
  },
  createNote(payload) {
    return api.post('/singgahsini/api/billing-tracker/note', payload);
  },
  updateNote(noteId, payload) {
    return api.put(`/singgahsini/api/billing-tracker/note/${noteId}`, payload);
  },
  updateFollowUpStatus(invoiceId, payload) {
    return api.put(
      `/singgahsini/api/billing-tracker/followup/${invoiceId}`,
      payload
    );
  },
  getAnnouncements() {
    return api.get(ANNOUNCEMENT_BASE_URL);
  },
  createAnnouncement(payload) {
    return api.post(ANNOUNCEMENT_BASE_URL, payload);
  },
  updateAnnouncement(id, payload) {
    return api.put(`${ANNOUNCEMENT_BASE_URL}/${id}`, payload);
  },
};

export { billingTrackerAPI };
