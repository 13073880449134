<template>
  <div class="billing-tracker-note-list">
    <template v-if="notes.length">
      <ul
        ref="list"
        class="billing-tracker-note-list__list bg-u-pl-lg"
        :style="{ height: noteListHeight }"
      >
        <li
          v-for="(note, i) in notes"
          :key="note.id"
          :class="{ 'bg-u-mt-xs': i > 0 }"
          :aria-hidden="i > 1 && !isExpanded ? 'true' : 'false'"
        >
          <!-- eslint-disable-next-line vue/require-component-is -->
          <component
            class="billing-tracker-note-list__item-wrapper"
            v-bind="getNoteItemWrapperElement(note.description)"
          >
            <div
              class="billing-tracker-note-list__item"
              @click="$emit('click-note-item', note)"
              role="button"
            >
              <bg-label-rainbow
                v-if="getTagLabel(note.tag)"
                class="billing-tracker-note-list__item-tag"
                :class="{ 'bg-u-mr-xs': !!note.description }"
              >
                {{ getTagLabel(note.tag) }}
              </bg-label-rainbow>

              <bg-text
                v-if="note.description"
                size="body-2"
                class="billing-tracker-note-list__item-description bg-u-text-neutral-900"
              >
                {{ note.description }}
              </bg-text>
            </div>
          </component>
        </li>
      </ul>

      <bg-button-icon
        v-if="notes.length > 2"
        class="billing-tracker-note-list__expand-toggle bg-u-mt-xs"
        icon-position="right"
        variant="tertiary-naked"
        size="sm"
        :icon="isExpanded ? 'chevron-up' : 'chevron-down'"
        @click="toggleExpand"
      >
        {{ this.isExpanded ? 'Sembunyikan' : 'Lihat lebih banyak catatan' }}
      </bg-button-icon>
    </template>

    <bg-button-icon
      v-else
      class="billing-tracker-note-list__add-button"
      icon="add-plus"
      variant="tertiary-naked"
      @click="$emit('click-add-note')"
    >
      Tambah Catatan
    </bg-button-icon>
  </div>
</template>

<script>
import { BgLabelRainbow, BgButtonIcon, BgTooltip, BgText } from 'bangul-vue';
import { TAG_OPTIONS_MAP } from '../../constants/FilterData';

const TWO_NOTES_HEIGHT = '64px';

export default {
  name: 'BillingTrackerNoteList',
  components: {
    BgLabelRainbow,
    BgButtonIcon,
    BgText,
  },
  props: {
    notes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      noteListHeight: TWO_NOTES_HEIGHT,
    };
  },
  methods: {
    async toggleExpand() {
      this.isExpanded = !this.isExpanded;

      if (this.isExpanded) {
        await this.$nextTick();

        const { list } = this.$refs;
        this.noteListHeight = `${list.scrollHeight}px`;
      } else {
        this.noteListHeight = TWO_NOTES_HEIGHT;
      }
    },
    getTagLabel(tag) {
      return TAG_OPTIONS_MAP[tag] || '';
    },
    getNoteItemWrapperElement(noteDescription) {
      const hasValidNoteDescription =
        typeof noteDescription === 'string' &&
        noteDescription.trim().length > 0;

      if (hasValidNoteDescription) {
        return {
          is: BgTooltip,
          variant: 'dark',
          trigger: 'hover',
          closable: false,
          text: noteDescription,
        };
      }

      return {
        is: 'div',
      };
    },
  },
};
</script>

<style lang="scss" src="./BillingTrackerNoteList.scss" scoped></style>
